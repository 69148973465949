<template>
  <div>
    <Card>
      <template #header>Kullanım Raporu</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-dropdown id="dropdown-action"
                        variant="outline-light"
                        :text="`Arama Tipi : ${searchType}`"
                        class="mr-2">
              <b-dropdown-item @click="searchType='E-Mail'">E-Mail</b-dropdown-item>
              <b-dropdown-item @click="searchType='Cihaz'">Cihaz</b-dropdown-item>
              <b-dropdown-item @click="searchType='Tarayıcı'">Tarayıcı</b-dropdown-item>
              <b-dropdown-item @click="searchType='Ülke'">Ülke</b-dropdown-item>
              <b-dropdown-item @click="searchType='Şehir'">Şehir</b-dropdown-item>
            </b-dropdown>
          </b-form-group>

          <Input placeholder="Ara.."
                 v-model="search"
                 class="mr-2" />
        </div>
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-button variant="primary"
                      @click="exportReport()">DIŞA AKTAR</b-button></b-form-group>
        </div>
      </div>
    </Card>

    <List :header="header"
          :data="items"
          :pagination="pagination"
          :loading="loading"
          @updatePage="updatePage"
          moduleName="Ürün Raporu"
          :moduleCreateEvent="false"
          :moduleSearch="search">
      <template v-slot:item.visitorEmail="{ item }">
        {{  item.visitorEmail }}
      </template>
      <template v-slot:item.creationTime="{ item }">
        {{
          $customFunctions.setMomentWithFormat(
                item.creationTime,
                'DD.MM.yyyy HH:mm'
              )
        }}
      </template>
      <template v-slot:item.userAgent="{ item }">
        <span v-b-tooltip.hover="`${item.visitorUserAgent}`">
          {{  item.visitorUserAgentDevice?item.visitorUserAgentDevice + ' / ':'' }} {{  item.visitorUserAgentBrowser }}
        </span>
      </template>
      <template v-slot:item.location="{ item }">
        {{  item.visitorUserInfoCountry?item.visitorUserInfoCountry + ' / ':'' }} {{  item.visitorUserInfoCity?item.visitorUserInfoCity:'-' }}
      </template>
    </List>
  </div>
</template>

<script>
export default {
  name: "companyProductReportList",
  data() {
    return {
      search: "",
      searchType: "E-Mail",
      loading: false,
      header: [
        { text: "Tarih", value: "creationTime" },
        { text: "E-Mail", value: "visitorEmail" },
        { text: "Kod", value: "visitorCode" },
        { text: "IP Adresi", value: "visitorUserIpAddress" },
        { text: "Cihaz", value: "userAgent" },
        { text: "Lokasyon", value: "location" },
      ],
      items: [],
      pagination: {},
      meta: {},
      page: 1,
    };
  },
  created() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;

      this.items = [];
      this.totalItems = 0;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `${this.$route.params.companyId}/companyProduct/${
        this.$route.params.productId
      }/report?Page=${this.page ? this.page : 1}&limit=${queryLimit}`;

      if (this.search) {
        let searchKey = "";

        switch (this.searchType) {
          case "E-Mail":
            searchKey = "userEmail";
            break;
          case "Tarayıcı":
            searchKey = "userBrowser";
            break;
          case "Cihaz":
            searchKey = "userDevice";
            break;
          case "Ülke":
            searchKey = "userCountry";
            break;
          case "Şehir":
            searchKey = "userCity";
            break;
        }

        query += `&${searchKey}=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
          if (res.success == true && res.count > 0) {
            this.items = res.data;

            this.pagination = {
              current_page: res.page,
              total_pages: res.pageCount,
              total_items: res.count,
            };
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePage(value) {
      this.page = value.current_page;

      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth",
      // });
    },
    exportReport() {
      let query = `${process.env.VUE_APP_BASE_URL}${this.$route.params.companyId}/companyProduct/${this.$route.params.productId}/report/export`;

      if (this.search) {
        let searchKey = "";

        switch (this.searchType) {
          case "E-Mail":
            searchKey = "userEmail";
            break;
          case "Tarayıcı":
            searchKey = "userBrowser";
            break;
          case "Cihaz":
            searchKey = "userDevice";
            break;
          case "Ülke":
            searchKey = "userCountry";
            break;
          case "Şehir":
            searchKey = "userCity";
            break;
        }
        query += `?${searchKey}=${this.search}`;
      }
      window.open(query, "_blank");
    },
  },
  watch: {
    page: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.page = 1;

      this.getList();
    },
    searchType: function (params) {
      this.page = 1;
      this.getList();
    },
  },
};
</script>
